import { createTheme } from "@mui/material";
import {
  black,
  green,
  greyCoolBci,
  greyLight,
  greyRegular,
  greyText,
  newGray,
  redBci,
  tortoise,
  tortoiseLight,
  white,
} from "../../styles/colors";
import { default as defaultShadows } from "@mui/material/styles/shadows";

const MainTheme = createTheme({
  components: {
    // Baseline
    MuiCssBaseline: {
      styleOverrides: () => ({
        html: {
          fontSize: "0.875rem",
          lineHeight: 1.43,
        },
        body: {
          fontSize: "1rem", // 14px
          lineHeight: "1.429rem", // 14px * 1.43
        },
      }),
    },

    // Popover
    MuiPopover: {
      styleOverrides: {
        root: {
          background: "rgba(0,0,0,.2)",
          "&#menu-": {
            backgroundColor: "transparent",
          },
        },
        paper: {
          borderRadius: "8px",
        },
      },
    },

    // Button
    MuiButtonBase: {
      styleOverrides: {
        root: {
          background: "transparent",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: "inherit",
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          outline: "none !important",
          minWidth: 32,
          textTransform: "unset",
          fontWeight: 700,
          fontSize: "14px",
          padding: "5px 16px",
        },
        contained: {
          border: "none",
          "&:hover": {
            border: "none",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: "3px",
          fontSize: "1.125rem",
        },
      },
    },

    // Form
    MuiFormControl: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          rowGap: 5,
          "&.form-control-full": {
            width: "100%",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          "&.Mui-error fieldset": {
            borderColor: "red !important",
          },
          "&.Mui-disabled": {
            backgroundColor: "#FBFBFB",
            "& .MuiInputBase-input": {
              textFillColor: black,
            },
            "& .MuiOutlinedInput-notchedOutline ": { borderColor: "#E8E8E8" },
          },
        },
        input: {
          padding: `8.145px 10px`,
          fontSize: 14,
        },
        inputMultiline: {
          padding: 0,
        },
        notchedOutline: {
          borderColor: "#E8E8E8",
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: newGray,
          padding: 6.5,
          "&.nopadding": {
            padding: 0,
          },
        },
        indeterminate: {
          color: redBci,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: newGray,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked.Mui-disabled": {
            color: "#FFF",
          },
        },
      },
    },

    // Pagination
    MuiPagination: {
      styleOverrides: {
        ul: {
          "& li:last-child button.Mui-disabled, & li:first-of-type button.Mui-disabled":
            {
              backgroundColor: white,
              borderColor: greyRegular,
              opacity: 1,
              "& path": {
                color: greyText,
              },
            },
          "& li:last-child button, & li:first-of-type button": {
            backgroundColor: redBci,
            "& path": {
              color: white,
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          background: white,
          "&$selected": {
            backgroundColor: white,
            borderColor: redBci,
          },
        },
      },
    },

    // Accordion
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "0 !important",
        },
      },
    },

    // Backdrop
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // backgroundColor: 'rgba(0,0,0,.2)'
        },
      },
    },

    // Dialog
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 8,
          boxShadow: "none",
        },
        paperWidthSm: {
          maxWidth: 816,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "20px 22px",
          fontSize: 16,
          fontWeight: 700,
          color: redBci,
          borderBottom: "1px solid #E0E0E0",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "21px 31px",
          borderTop: "1px solid #E0E0E0",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "2px 2px 10px rgba(0,0,0,0.05)",
          border: "1px solid #E0E0E0",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px",
          "&:last-child": {
            paddingBottom: "auto",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: white,
          border: `1px solid #E0E0E0`,
          color: "black",
        },
        arrow: {
          backgroundColor: white,
          color: "#E0E0E0",
        },
      },
    },

    // Tab
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "unset",
          "&.Mui-selected": {
            color: black,
          },
        },
      },
    },

    // Alert
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: "#FFF1E6",
        },
        standardInfo: {
          backgroundColor: "rgba(0, 57, 255, 0.05)",
          border: "1px solid rgba(0, 57, 255, 0.1)",
        },
        standardWarning: {
          backgroundColor: "#FFFADF",
          border: "1px solid #FFDF94",
        },
      },
    },

    // Table
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: white,
        },
      },
    },

    // Divider
    MuiDivider: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },

    // Typography
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
        },
      },
    },

    // Chip
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
  typography: {
    xxLarge: {
      fontSize: "1.714rem", // 24px
      lineHeight: "2.451rem", // 24px * 1.43
    },
    xLarge: {
      fontSize: "1.571rem",
      lineHeight: "2.247rem",
    },
    large: {
      fontSize: "1.43rem", // 20px
      lineHeight: "2.042rem", // 20px * 1.43
    },
    subtitle1: {
      fontSize: "1.285rem", // 18px
      lineHeight: "1.837rem", // 18px * 1.43
    },
    subtitle2: {
      fontSize: "1.143rem", // 16px
      lineHeight: "1.635rem", // 16px * 1.43
    },
    caption: {
      fontSize: "1rem", // 14px
      lineHeight: "1.429rem", // 14px * 1.43
    },
    body1: {
      fontSize: "0.857rem", // 12px
      lineHeight: "1.224rem", // 12px * 1.43
    },
    body2: {
      fontSize: "0.929rem", // 13px
      lineHeight: "1.328rem", // 13px * 1.43
    },
    small: {
      fontSize: "0.714rem", // 10px
      lineHeight: "1.021rem", // 10px * 1.43
    },
  },
  palette: {
    primary: {
      main: redBci, // red-bci
    },
    secondary: {
      main: greyCoolBci, // grey-cool-bci
      dark: black, // black-bci
      light: greyRegular, // grey-regular-bci
      contrastText: greyLight, // grey-light-bci
    },
    info: {
      main: black, // blue-bci
    },
    warning: {
      main: tortoise, //tortoise-bci,
      light: tortoiseLight, //tortoise-light-bci
    },
    success: {
      main: green, //tortoise-bci,
      light: green, //tortoise-light-bci
    },
    "bci-black": {
      main: black,
    },
    text: {
      primary: black, // black-bci
      secondary: black, // blue-bci
    },
    error: {
      main: redBci,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 768,
    },
  },
  shadows: [
    defaultShadows[0],
    "0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 1px 2px 0px rgba(0, 0, 0, 0.10);", // override shadow[1]
    "rgba(0, 0, 0, 0.08) 0px 0px 2px 1px", // override shadow[2],
    "rgba(0, 0, 0, 0.25) 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 1px;", // override shadow[3],
    defaultShadows[4],
    defaultShadows[5],
    defaultShadows[6],
    defaultShadows[7],
    defaultShadows[8],
    defaultShadows[9],
    defaultShadows[10],
    defaultShadows[11],
    defaultShadows[12],
    defaultShadows[13],
    defaultShadows[14],
    defaultShadows[15],
    defaultShadows[16],
    defaultShadows[17],
    defaultShadows[18],
    defaultShadows[19],
    defaultShadows[20],
    defaultShadows[21],
    defaultShadows[22],
    defaultShadows[23],
    defaultShadows[24],
  ],
});

declare module "@mui/material/styles" {
  interface Palette {
    "bci-black": Palette["primary"];
  }
  interface PaletteOptions {
    "bci-black": PaletteOptions["primary"];
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
  }

  interface TypographyVariants {
    xxLarge: React.CSSProperties;
    xLarge: React.CSSProperties;
    large: React.CSSProperties;
    small: React.CSSProperties;
  }

  // allow configuration using `createTheme()`
  interface TypographyVariantsOptions {
    xxLarge?: React.CSSProperties;
    xLarge?: React.CSSProperties;
    large?: React.CSSProperties;
    small?: React.CSSProperties;
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    "bci-black": true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    xxLarge: true;
    xLarge: true;
    large: true;
    small: true;
  }
}

export default MainTheme;
