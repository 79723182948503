import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import Providers from "./providers";
import "@tnlm/utils/sentry";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <Providers>
    <App />
  </Providers>
);
