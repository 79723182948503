export const redBci = "#ff0000";
export const redLight = "#ffe8e8";
export const redLightSoft = "#fff0f1";

export const greyDefault = "#f3f3f3";
export const greyMain = "#b0b0b0";
export const greyLighter = "#f9fafc";
export const greyLight = "#f7f7f7";
export const greyRegular = "#e0e0e0";
export const greyCoolBci = "#808080";
export const greyText = "#6a6a73";
export const greyTableStripped = "#f7f8fa";
export const greyTableBorder = "#ced3d8";
export const greySlate = "#858BA0";
export const green = "#0da738";

export const black = "#454550";

export const blue = "#215d95";
export const blueLight = "#ecf7ff";

export const tortoise = "#03989e";
export const tortoiseLight = "#d8f4f5";

export const white = "#fff";
export const ghostWhite="#F7F6F9";

// New Colors
export const gray94 = "#f0f0f0";
export const newGray = "#cac8c8";
export const magenta = "#ff7676";
export const bciBlue = "#0039ff";
export const darkPurple = "#7b1fa2";
export const lightPurple = "#f3e5f5";
export const bciAqua10 = "#e4f8f9";
export const lightGray = "#E8E8E8";
