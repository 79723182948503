import { create } from "zustand";
import { persist } from "zustand/middleware";

type State = {
  isQaTest?: boolean;
  subType: "LM" | "LM_LITE";

  // this is for limit slice on api suggestions (PP & KA)
  suggestionApiLimitSlice?: number;
};

type Action = {
  setIsQaTest: (isQaTest: boolean) => void;
  setSubType: (subType: State["subType"]) => void;
  setSuggestionApiLimitSlice: (suggestionApiLimitSlice: number) => void;
  clearFilter?: () => void;
};

const initialFilter: State = {
  isQaTest: false,
  suggestionApiLimitSlice: undefined,
  subType: "LM",
};

const useDevToolStore = create<State & Action>()(
  persist(
    (set) => ({
      ...initialFilter,
      setIsQaTest: (isQaTest) => set({ isQaTest }),
      setSuggestionApiLimitSlice: (suggestionApiLimitSlice) =>
        set({ suggestionApiLimitSlice }),
      setSubType: (subType) => set({ subType }),
      clearFilter: () => set({ ...initialFilter }),
    }),
    {
      name: "devtool-storage",
      partialize: ({ isQaTest, suggestionApiLimitSlice, subType }) => ({
        isQaTest,
        suggestionApiLimitSlice,
        subType,
      }),
    }
  )
);

export default useDevToolStore;
